import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import YoutubeVideo from "components/Motion_Detection/FTP_Server_Setup/YoutubeVideoCardFTPServer";
import NavButtons from 'components/Motion_Detection/NavButtons';
import ForumBox from 'components/Motion_Detection/FTP_Server_Setup/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "FTP Server Setup",
  "path": "/Motion_Detection/FTP_Server_Setup/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Setting up a FTP server on your Windows Computer",
  "image": "./MD_SearchThumb_FTPServerSetup.png",
  "social": "/images/Search/MD_SearchThumb_FTPServerSetup.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-FTP-Server_white.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='FTP Server Setup' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Setting up a FTP server on your Windows Computer' image='/images/Search/MD_SearchThumb_FTPServerSetup.png' twitter='/images/Search/MD_SearchThumb_FTPServerSetup.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/FTP_Server_Setup/' locationFR='/fr/Motion_Detection/FTP_Server_Setup/' crumbLabel="FTP Server" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "setting-up-a-filezilla-server-on-your-windows-computer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#setting-up-a-filezilla-server-on-your-windows-computer",
        "aria-label": "setting up a filezilla server on your windows computer permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setting up a Filezilla server on your Windows Computer`}</h2>
    <p>{`Your camera can upload pictures during an alarm event or you can also upload pictures in a defined time period to any kind of FTP server. Besides online offers for FTP servers or Routers which can function as a FTP server you can also set up your local computer or server to function as a FTP server in order to receive pictures from the camera.`}</p>
    <YoutubeVideo mdxType="YoutubeVideo" />
    <h3 {...{
      "id": "download-filezilla-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#download-filezilla-server",
        "aria-label": "download filezilla server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Download FileZilla Server`}</h3>
    <p>{`All you need to do is get a FTP tool like FileZilla:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "759px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/db9c73a270fecd75b5f6c32557cef1b2/ef3e1/Filezilla_install_download.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.52173913043479%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACqElEQVQoz02P608TWRiH599jv+x+M5vw2V0xJl7iLtUSl4tKuy4J2xoU8BZXNysVJCLQTmk7QKGWXixiNJ1xptOWFpmWacEywxrzmA6N+uHJ856cnN/7O8LcP/8yP3GH6XsPmPsvQDq3yebDR2Ru3iI7dputYJBXuU0y6QzJZIqNjTSp1DHpVIZ0OkNiLU42kyWxnkCIe73kenp4MzaGGgjwbnwcLRJBXVxka/RvlECAPdOkeXREw7Jo2rYzf0+jQ3sWHp/8hSddXSxcvEjY5eJFdzfiqVNEensJnznD8xMnCPX1Ifb3HzMwQMTnI+L3E/H7HKJ+fwcfgjQ1RerZDMmnT0nPzPByeprg5CShyQnC9+8jx2IoS2FkUUQRRcdyKEg+2Gax428IsdVVgtEoW/k870slqjUDWdfRtrcpVCrsNkzqBwfUOpiHLczDQxqHFvudbzq2jzj4/whhdWUFMRTiZSKBpqrsVCqUy2Uq29sUdR29UKCgac5dm/ZZ1zRqhsFevc5erUbdMDDrdcdCPB5naWmJcDhMJptl19ilVC5Tre5QLBaPwztL2kFtF0sl9FKJ96qKoihfrcgyQjQaJRQKIUmSE7hXrSK/fYuqaei6jqwoqKrqtLU+f8a2bez9fexmE6texzYbfGq1sD5+xGq1EJYlyQnbfP3aCWiaJrsfdkgkEqytrbG+vo4UiyFGIuRmZykkN9h6MU9qfoFXC4skZ2dZmZ4he+s2+vM5BOeBJJHL5cjnZSzLQi8W6e8f4MaNv7g6NMTlS5f4taeH7h+6GLvyB96BQTyjowx5PPS63Zw+d5aff/qRu5fdxw2Xl5edJu2WrVYLwzDw+XyMjIzg8XgYHh7G6/Fw5do1xKkAdwaH+NPdh9fl4vpvv+PtdeE+fwFxfIIva3XT1EP9U+oAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/db9c73a270fecd75b5f6c32557cef1b2/e4706/Filezilla_install_download.avif 230w", "/en/static/db9c73a270fecd75b5f6c32557cef1b2/d1af7/Filezilla_install_download.avif 460w", "/en/static/db9c73a270fecd75b5f6c32557cef1b2/f9450/Filezilla_install_download.avif 759w"],
              "sizes": "(max-width: 759px) 100vw, 759px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/db9c73a270fecd75b5f6c32557cef1b2/a0b58/Filezilla_install_download.webp 230w", "/en/static/db9c73a270fecd75b5f6c32557cef1b2/bc10c/Filezilla_install_download.webp 460w", "/en/static/db9c73a270fecd75b5f6c32557cef1b2/3440c/Filezilla_install_download.webp 759w"],
              "sizes": "(max-width: 759px) 100vw, 759px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/db9c73a270fecd75b5f6c32557cef1b2/81c8e/Filezilla_install_download.png 230w", "/en/static/db9c73a270fecd75b5f6c32557cef1b2/08a84/Filezilla_install_download.png 460w", "/en/static/db9c73a270fecd75b5f6c32557cef1b2/ef3e1/Filezilla_install_download.png 759w"],
              "sizes": "(max-width: 759px) 100vw, 759px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/db9c73a270fecd75b5f6c32557cef1b2/ef3e1/Filezilla_install_download.png",
              "alt": "Filezilla FTP Server for Windows",
              "title": "Filezilla FTP Server for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Enter the `}<a parentName="p" {...{
        "href": "http://filezilla-project.org/"
      }}>{`Filezilla Website`}</a>{` (1) and download the server edition of FileZilla (2).`}</p>
    <h3 {...{
      "id": "install-the-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#install-the-server",
        "aria-label": "install the server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Install the Server`}</h3>
    <p>{`If you run into any troubles during the installation process, please follow our installation guide `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/FTP_Server_Setup/Install_FileZilla_Server/"
      }}>{`here`}</a></p>
    <h3 {...{
      "id": "start-the-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#start-the-server",
        "aria-label": "start the server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Start the Server`}</h3>
    <p>{`After installation please start the FTP server and connect to your localhost (127.0.0.1) server.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "259px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1e6cd5474cc70dae0b275b2a522710b2/a2ead/Filezilla_install6.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADuElEQVQ4y12S3WtbdRyHczFmBa83nXgr7E4vRPwrRJ2gyKiI4Nq6MbQvc0Nhc+rFupW+rFQFZRerCq5dNuhr2qbNaU6bnJM2TZuTpCcvTXKS85L3rF1ZWx9JsrXOHzx8P/wuHj6/F9vx92Z55aLJyVaNUy0aJ77QeLlF41SrxslzaV5t03jpM40XmjM0fZqhqbmWGzQ1pzl2NsOJz5P80X2X5rO/Yzv+rsSbXTne+CrNO+0pXmuxeP28wekLOqe/1Hi7PcOxTwrYzlSxfVhpzEMq2D7Y5sWPLNa+6+TymW5s719z41rbYtwbY0KKM+mNMSnFmZITTHjjjHtiTEsxHPIR01K0zrM85Y0hr6zRd0fC9vXPS+TiTjzCCJHAND7xPsLMMLJ7FDPlYaewTjW3doS1xl41TCTgQHKP8igfoGIF2NsOMTHrw9baJ1DMxfCvBwiG1glvKkTjKsl0AsPKkssbWE+pZTOno2lhtpIqoYjCphoia2SolDVGxqSa0MVO1SASTZJMaSS2UhSKZWpr/+CIgwP4B9jbh1Q6jGkZqNEE0Vi8vre//7ghbOkVePIoQb6QplRKUyzWSJHPbVEoJA+xrDh6Vq1jZBX0bATLSpCzEmQyUUoFFfuEjO387SWSERfCwkNWpGlWZQeyZ5KNNSd+2cGKd6o+w8FF8maInB4kZ4TIGQqWHqxjZoPslEM8qAsHRFIxH3PzMyx7XHg8AuLSAh6vgCS78a0sI0kC0YiHvBnG0kNYuvJ0Nqg13i6FGw3b+gW2K1ncyzKBwAaiuIywKCL7VimVq/W7rG5XyRsKZlapt6kJay0bYuV5Ye2Vt4txdEujVMxSKRuUizqVsk4xr2EaSfSMSsEMU8qrlAsqBSvyn5b/E17od6NsSdx32HE67SzJ0/gD8whLYwjiGD7/LF7/HG5xAq84gctpRwm4KOUi9da1ps8fuW+RohVETQVJjf+Ncu0qkZ5bRG7dJNR9g3BPD/Efr5MUp9hMrBNVV0mnFQx9k3QqiJ5pXMWh8FyvwG4xjFWKow4NsjrmImUWWJgXCfgVtvIVvL/+SfSvYaKlCoGNIPOLIqJXJqWbbITXyWobVAohRsclbBcHl+BJjMe7Scr2u2i9QxTv2Yn/8hvZO8PkRx6i/XSTomsMLZ8kHlkh4HezGfbV/6SZDbFbVusOx5wf21ttD/h2yEl7/yxdvQ66vh+l4+o9Ll230/ks/2Cnq3+WzoEZLt2e4/LQAt8MOunon6FrYI6O/lmuDDn5+Mo4/wIVuSHfDczatQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1e6cd5474cc70dae0b275b2a522710b2/e4706/Filezilla_install6.avif 230w", "/en/static/1e6cd5474cc70dae0b275b2a522710b2/e90ce/Filezilla_install6.avif 259w"],
              "sizes": "(max-width: 259px) 100vw, 259px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1e6cd5474cc70dae0b275b2a522710b2/a0b58/Filezilla_install6.webp 230w", "/en/static/1e6cd5474cc70dae0b275b2a522710b2/652f5/Filezilla_install6.webp 259w"],
              "sizes": "(max-width: 259px) 100vw, 259px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1e6cd5474cc70dae0b275b2a522710b2/81c8e/Filezilla_install6.png 230w", "/en/static/1e6cd5474cc70dae0b275b2a522710b2/a2ead/Filezilla_install6.png 259w"],
              "sizes": "(max-width: 259px) 100vw, 259px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1e6cd5474cc70dae0b275b2a522710b2/a2ead/Filezilla_install6.png",
              "alt": "Filezilla FTP Server for Windows",
              "title": "Filezilla FTP Server for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "add-a-ftp-user",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-ftp-user",
        "aria-label": "add a ftp user permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a FTP User`}</h3>
    <p>{`Now please set up a user for your cameras account. By clicking Edit and then Users you can access the user settings.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "514px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/77207d7c6c9dd40ea2e5c01926f35ab9/dea13/Filezilla_install8.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACfUlEQVQ4y+2TS2sTYRSG5wdYdC8uBTcuXIoLf4qLWnWhSEE34satiigiLVS8gEItor1a05Ym2NradJqkaZpkYqJmkplcJzWZyaVtJo9kJpGmVFpKlx54eM85H+cdhu98woVLw5y/m+HcbZWT1xTO9KY4fVPl7K0Up67n6Lpc4PgVzdKunoKt3c1a41i3xokejYv3dK4+Vrlz4w3CqMuHc+Ebbx0+egcifPWGkWQF9/pPFteiuMQmMStf8EdZ3M1aFDEQQYpKjE0sIaxLfjyeCYJOB2OPhnj14B1TT98TD6wgyyGCXjfSqpeSFmYjE6KQXkdLBTrIKgH0QgjnvBfh09QcHr8Pz+wc030fGO4fwfl8nLVlETWTJRaLEvsR41dcJh6XyWsa5UoVwyhjlG10vYxZr7HkTSFMuxYR3UsEgkEkKUA2nSCvpVFUlWq12kGtVsM0TXZHo6X+cBFh2RdCScoYhkGlUqVSrWHohmXwr2g0Gh3U6/ZHfKECQnajcuDBNu2zdtTNRstwA0HJFq3CNA82vFd0GKq5km24z9B/w4MZNg7JrltuGZrm4Q3bexi09rDKUYVfKiK4V0TUZJhkIsz3iI9I2ENStmuLHXlCDhGL+pHCHiKSFyUhWX05HiafkZj5sooQjMQoG3lKpTyZdIJUKm7lesnutXOrLuYoaGnSKdlC1+3z4u8c25sFFlfiCJlC5Wh/Wc3pnc9rn/VpPtF63b5Zs8XW9o5bXo1kyG5souQqKPkKqlYjpdX+6k6ava2tMlCGRkubmAZQxeVWEB4OOOl7PcOzl9M8GZjkxeAcQ+MrDI6Ke/JxUmTUITLisLXJ8GeRyVkP9/vn+QOrfRymbQgxLwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/77207d7c6c9dd40ea2e5c01926f35ab9/e4706/Filezilla_install8.avif 230w", "/en/static/77207d7c6c9dd40ea2e5c01926f35ab9/d1af7/Filezilla_install8.avif 460w", "/en/static/77207d7c6c9dd40ea2e5c01926f35ab9/dfeb9/Filezilla_install8.avif 514w"],
              "sizes": "(max-width: 514px) 100vw, 514px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/77207d7c6c9dd40ea2e5c01926f35ab9/a0b58/Filezilla_install8.webp 230w", "/en/static/77207d7c6c9dd40ea2e5c01926f35ab9/bc10c/Filezilla_install8.webp 460w", "/en/static/77207d7c6c9dd40ea2e5c01926f35ab9/7a333/Filezilla_install8.webp 514w"],
              "sizes": "(max-width: 514px) 100vw, 514px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/77207d7c6c9dd40ea2e5c01926f35ab9/81c8e/Filezilla_install8.png 230w", "/en/static/77207d7c6c9dd40ea2e5c01926f35ab9/08a84/Filezilla_install8.png 460w", "/en/static/77207d7c6c9dd40ea2e5c01926f35ab9/dea13/Filezilla_install8.png 514w"],
              "sizes": "(max-width: 514px) 100vw, 514px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/77207d7c6c9dd40ea2e5c01926f35ab9/dea13/Filezilla_install8.png",
              "alt": "Filezilla FTP Server for Windows",
              "title": "Filezilla FTP Server for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In General (1), please choose Add (2) and type in a user name for your cameras account (3). After that you can choose a password for this account (4) and click OK to save the settings.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "654px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7162b3fc7fa3aa8c0eeb5909f492fb18/68e9c/Filezilla_install9.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "83.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAA7EAAAOxAGVKw4bAAADxElEQVQ4y2WSS28bVQCFZ4XEb2DBkhV/ArFABalLNkhFAlWgAhsKrVK6YIWQ2PShNgURUJM2lEcKNE3ipokbbCeN87DHM74z9njGHnuenrGTkNhVSuIP2Q6lao/06ZzF1dG5V1eSjl1COnaF90ZuMTImOH1N5dNRldPXioP8yWWFjy4pfPwcBU5dVDh1QefMV/O8ePwq0rHLSC+9+yuvnLzDD1Ob3FuuMv2XyWymSmLZZjZjM5OucTf1DOkaM0d5LlPntzmdVz+c5uV3biB9f6fE1ILN5LzNzYTJRKLK+Iw14OacyWSfxFPMV5mcNbgxZzIxazCZMJiYq3DznsWdPzaRNtJp6lNjNJPT7EYW3vIixtI8ZnqBdj7Jlvzgf3JJtlYTbHsyvlcijl32dhx2tx26uw6OayJVjAba3WnUhSRbUUS48Cd29j7uxiJuLoWbz+Dm00PfTOHN/U4YasSRTV+HvSF9eZ6HVHEi/u7u0X18wP5+B3NtAW1pFrE0Q319Cb+wjJfP4MmZgftijcBTnxT2er0Bffm+i2Q6Ef+p09llb6dE75HOYVfnnz3BYUfQ62ocdjXoaux3DeJQEDdr0IPewQG93uFw4dOF/dkHj3e5vVRkZKLK+QmT8zcszo7XODPe4Oy4zWcTLt/+XMBQ1tjaC3hWQeA/Xzg2XeTzMYORHw3OXa/wwWiV96/UOHmlyonLNt9cTbP58CHBeor6Ygr3fpJQU4jaPmZFR7KeuvKjRx00XaYsJ/CtDLv+Jp0oT6eZozvwTVpBAVEW1B7MkP/lFvLkdUQ2Sam8gVpYQ6o0wv7TcnBwSKezgywEZUOj3rCI/AbN2CeKA8KmT9xusrMdUiwquIHACUrYnsC2FbyGQsVQkBRdpR2VCHydKNDJqQIhcpimwLK0AVVLYFZUGvUydqNGTlZoBRpxoNHqf6FQpx3pOLaOpOoK27FOM9CIAoGil4gji7hpEQYWWy2buOXjBQ6tlksQVCkIg2hwXj9yjVZTo17TkLKFIna9QqVq0HAM1vMy+UKO1dU06XSS1WyG5ew6i6kVNvJ5hKagCEGrqQ+WtcKhx6GGXRP9hWXcepmCWsCqlTHL6yjyCkVlFa2YHebCCpr6kKopU6sWadgqniPwHTHw0BN02mVit4z0xrkp3v7yNukNi7q7hVmPqTntIY0W9lGuNtrYTkwrDomjIVEzZLvdRNEbnPh6hre+mEKSXr+I9OYooz+lWV7XSK3ppLIa6TVt6E+yzsqGTkHVKRSHyKqGppeYXpR54fh3SK9d4F91KpD/PqTbkAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7162b3fc7fa3aa8c0eeb5909f492fb18/e4706/Filezilla_install9.avif 230w", "/en/static/7162b3fc7fa3aa8c0eeb5909f492fb18/d1af7/Filezilla_install9.avif 460w", "/en/static/7162b3fc7fa3aa8c0eeb5909f492fb18/f8b48/Filezilla_install9.avif 654w"],
              "sizes": "(max-width: 654px) 100vw, 654px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7162b3fc7fa3aa8c0eeb5909f492fb18/a0b58/Filezilla_install9.webp 230w", "/en/static/7162b3fc7fa3aa8c0eeb5909f492fb18/bc10c/Filezilla_install9.webp 460w", "/en/static/7162b3fc7fa3aa8c0eeb5909f492fb18/d7085/Filezilla_install9.webp 654w"],
              "sizes": "(max-width: 654px) 100vw, 654px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7162b3fc7fa3aa8c0eeb5909f492fb18/81c8e/Filezilla_install9.png 230w", "/en/static/7162b3fc7fa3aa8c0eeb5909f492fb18/08a84/Filezilla_install9.png 460w", "/en/static/7162b3fc7fa3aa8c0eeb5909f492fb18/68e9c/Filezilla_install9.png 654w"],
              "sizes": "(max-width: 654px) 100vw, 654px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7162b3fc7fa3aa8c0eeb5909f492fb18/68e9c/Filezilla_install9.png",
              "alt": "Filezilla FTP Server for Windows",
              "title": "Filezilla FTP Server for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "add-a-local-directory",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-local-directory",
        "aria-label": "add a local directory permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a local Directory`}</h3>
    <p>{`Now we have to select the folder in which the camera will save the pictures. Please choose Shared folders and then Add to select the folder in which the pictures will be saved on your local hard drive. After selection the folder please mark the folder and set it as the Home Directory by clicking set as home dir. Please also make sure that under "Files" the Read and Write access rights are selected. Sometimes the default setting might be only Read. This would cause that you get an error message if you try to write any files...`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "657px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/719d08837f46f09689d2ccaf3dd3413b/a1253/Filezilla_install10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "83.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAA7EAAAOxAGVKw4bAAADzUlEQVQ4y12S24sbZRiHBxTv/Av8K6ReCCJK0faiLaIIgt6KvVIQtUdpLxW0FKrWdttttVSoF9Ktxe3B3e5mNyGbZJLNaWYyk0xmMplTzps02Sa72zySyap0X3j4fXzv+/5evoMgHLmIcPgSr31yk0NnVjhweoW3T4U4cCoU6P7jy7x5bIk3vlrewxKvfznJhTn42RzPHbmEcPgiwvPvXuXF928w+1uIcNJiIWryKGaxlLBZilf5O1rlYdRiOW4RilcCVhJTQnGLcMLh4b0UL330Ky+8dwXhzLU839/SuXjbYGauxOU5nctzZWbulLlyp8zsXYOZOwZnb1icul7l9C9VTlyrcnx2qievV/n8isPRH23OfhdFiCTW8RwV16vg+SaKpiDmZFRdw6qqWBWJWEbhg3NVDn/j8M63Nq9+7fHKSY99J1z2HXd4+ZjDh+d9Zs7PIyiGS1HNIOWTdDo+FTOPGF9Ezodx7TyWkUIuZAlHw6yu/EUk8pB4bJH11AoFJY4sraEoCdTCGpFoAqFg+Li2heO4PO5v0N1osL29zZMnA0ajIfCUbn+ErKSRM4/QtRi+k8O3c3QaKu16gaav0O+opLM5hILp829sbj6m122wN9q9EY5Xw7IrJNdTpFIitm3R73cZDHrB8EnvaqL0v+EYgmSvW5+6jHd2d6HdHeLVmgxH21iWga7lKes6hmFg2w7djW5QFxLdZw03N3u0+x22gdGE7an35MiONx30ZNDB0mOEUzpbW1sMh8OAScSz9rOGw2GfwnKY9MI62eUMyYKPUfXQDIuq6wdVk6HVskg4lqbZbE17x1MHtVSdvPLU8OkYNocDKvdvo879jvFgDkmKUyquE0+JVGw3qOs0TbRcCFUrsTN12r0YkFQbIa0o9DoVmg2Tml8ilVhgLXSXtcg80cg8ydg9wuFFTMtgvDNgo2VSs3M4VplBy2Or12I02GA83iSRKSNk5Ay9pkLDl2l4EoaeDP5UUY1RLMQoF2Ok0nEUVcazJaoVGbdWwhYjpC7dRLx6C/HPecRskoVQCmEtk8dxilQsDdfVadVV2o3iLhqdZhHf1ZALMqoqTdEkClIWWUygiAmkbJqclGF1LY0gaRqtWhlJyWJUVNq1PK09tOt5ui2JjaZEpyHRqU9UptNSAnptBbZ0HjeLCB+fu8cXPz1gVdQxrCZls0a5Useo1J9R3axjWjV8z/8Pz/Vo1GrkFIszs4t8euE+gvDWDwgHf+baHxHUokFeM8lrBlKgZqDBWjVRSwa+V6a2i+eWg1d/FM0jHJpB2H+BfwDctpH+uRJnmAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/719d08837f46f09689d2ccaf3dd3413b/e4706/Filezilla_install10.avif 230w", "/en/static/719d08837f46f09689d2ccaf3dd3413b/d1af7/Filezilla_install10.avif 460w", "/en/static/719d08837f46f09689d2ccaf3dd3413b/b5dc5/Filezilla_install10.avif 657w"],
              "sizes": "(max-width: 657px) 100vw, 657px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/719d08837f46f09689d2ccaf3dd3413b/a0b58/Filezilla_install10.webp 230w", "/en/static/719d08837f46f09689d2ccaf3dd3413b/bc10c/Filezilla_install10.webp 460w", "/en/static/719d08837f46f09689d2ccaf3dd3413b/b9f96/Filezilla_install10.webp 657w"],
              "sizes": "(max-width: 657px) 100vw, 657px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/719d08837f46f09689d2ccaf3dd3413b/81c8e/Filezilla_install10.png 230w", "/en/static/719d08837f46f09689d2ccaf3dd3413b/08a84/Filezilla_install10.png 460w", "/en/static/719d08837f46f09689d2ccaf3dd3413b/a1253/Filezilla_install10.png 657w"],
              "sizes": "(max-width: 657px) 100vw, 657px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/719d08837f46f09689d2ccaf3dd3413b/a1253/Filezilla_install10.png",
              "alt": "Filezilla FTP Server for Windows",
              "title": "Filezilla FTP Server for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Set an upload directory for your camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "656px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3c07332bce555ce4bf5df60121c333d4/748f4/Filezilla_install11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "82.17391304347825%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADSElEQVQ4y3WS224bVRSG544nQdxww0tEJWngAoknACFxwyPABUJyEIdKtFKq0gtIhVAlmoQ0kUsCCY7PznjO40Mz4/EcbSexc3ad2B/yWEkr0m7p07/2mr3/vfaaLQizCwjTj/nihxQL6w7zTx0erDk8THr8nPS4v+oy/7TJ/JW+wt0/XR6u+Xx1X0KY/p23Zh8gTH2pcTvhM5XoMJPwmU6EzMy1mElEzCRCZr+NYm7Phdca5+dCPvo+5OMfIz78rs0HcyHffL2MsLIlEjYVoqBOb9/BsjRKZZHnuxqdyKRpS7iOTCs0sW2dwNPptnUUQyeZ0UimZVZTEn8XFLYKKsK/ioe9a5LLbdPphPh+HUXOUK+W2OtYuE0d3zXjuKjmkaQU+y2dpudQ8/vojWNU+xjTPSErGgibksPpUZdu75D+izO63RbD4YjBYMBwOARGjMdg0Ec2ihRySQJXodf1+f+Q9RrCZrlxnTg/P6XXa13PR6MxLw13LROzqsSGB/tefNTl5TBmfHBZq44NnXjDcDQxPDq8Mhy9wnjjCwpKnnxhk8hX6R4Eb6rQua6m3z+l1Q44PIXeySW90xFn/QsYXXJ8doZa38HUUrRCjaZXwwv8uJeNpkMYeeR2lEkPr+q5GPSxGs+RK030XY8dzaJuOzh+A6MsYywtoy4uUlt5Qk3Nk8plSefSpLJp8qUsm5kCwj+iPb4wg4sLzs5PqFULyFIKTU1TFreQ5W3KUhox8xfi4mPEpSfIK39gyClkJTt5EXUJz9UoiGWEZ1mZw70KUWASBQZKeZNsepV8Zo1cZo1iPkkxl4z/blHcoCRuUCitI5bWMbVtrHohfq9HB1UkVRkbShztV4lCk3Zk0goMQk8j8vU4Hmscx6pNdPw90CfrAp12VKHbqSDKCsLKtkIQWNStGo67y37b5KBTmdCuvIxfQ7dTjXWvZcZFxRUubanYVpViuUylXsN1DNzmG3AMnMZNxvm9qEpRVBDe/uQR7372K7+sqNQaPcpmG7nauYFU6aDX27h+hOe3cP0WTS+i3e6wvFXjvc9/451PFxCE9+8iTN3jzqNnOJaEZevYtoptazdwGlrcxysCT+Voz2BxI4dw6x7CrZ/4D9FCWFIZj3paAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3c07332bce555ce4bf5df60121c333d4/e4706/Filezilla_install11.avif 230w", "/en/static/3c07332bce555ce4bf5df60121c333d4/d1af7/Filezilla_install11.avif 460w", "/en/static/3c07332bce555ce4bf5df60121c333d4/0ec20/Filezilla_install11.avif 656w"],
              "sizes": "(max-width: 656px) 100vw, 656px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3c07332bce555ce4bf5df60121c333d4/a0b58/Filezilla_install11.webp 230w", "/en/static/3c07332bce555ce4bf5df60121c333d4/bc10c/Filezilla_install11.webp 460w", "/en/static/3c07332bce555ce4bf5df60121c333d4/31099/Filezilla_install11.webp 656w"],
              "sizes": "(max-width: 656px) 100vw, 656px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3c07332bce555ce4bf5df60121c333d4/81c8e/Filezilla_install11.png 230w", "/en/static/3c07332bce555ce4bf5df60121c333d4/08a84/Filezilla_install11.png 460w", "/en/static/3c07332bce555ce4bf5df60121c333d4/748f4/Filezilla_install11.png 656w"],
              "sizes": "(max-width: 656px) 100vw, 656px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3c07332bce555ce4bf5df60121c333d4/748f4/Filezilla_install11.png",
              "alt": "Filezilla FTP Server for Windows",
              "title": "Filezilla FTP Server for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And make it the Home Directory.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      